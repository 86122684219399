import Puzzles from '../Puzzles';
import scramble_3x3x3 from './3x3x3';
import scramble_5x5x5 from './5x5x5';
import scramble_megaminx from './megaminx';
import scramble_square1 from './square1';

const scramblers = {
  [Puzzles['3x3x3']]: scramble_3x3x3,
  [Puzzles['5x5x5']]: scramble_5x5x5,
  [Puzzles['Megaminx']]: scramble_megaminx,
  [Puzzles['Square1']]: scramble_square1,
};

export default scramblers;
