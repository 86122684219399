import { useEffect } from 'react';

function useKeyboardListener(listener) {
  useEffect(() => {
    document.addEventListener('keyup', listener);
    return () => document.removeEventListener('keyup', listener);
  }, [listener]);
}

export default useKeyboardListener;
