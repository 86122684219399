import Puzzles from "./Puzzles";
import styles from "./PuzzleSelector.module.css";

function PuzzleSelector({value, onChange}) {
  return (
    <select
      className={styles.selector}
      value={value}
      onChange={event => onChange(event.target.value)}
    >
      <option value={Puzzles["3x3x3"]}>3x3x3</option>
      <option value={Puzzles["5x5x5"]}>5x5x5</option>
      <option value={Puzzles.Megaminx}>Megaminx</option>
      <option value={Puzzles.Square1}>Square-1</option>
    </select>
  );
}

export default PuzzleSelector;
