import { useEffect, useRef } from 'react';
import { PNG, Type, Colors } from 'sr-puzzlegen';
import Puzzles from './Puzzles';
import useKeyboardListener from './useKeyboardListener';
import useViewportDimensions from './useViewportDimensions';
import styles from './PreviewModal.module.css';

const MODAL_THRESHOLD = 769;

const RenderMode = {
  MODAL: 'MODAL',
  POPOUT: 'POPOUT',
};

// The default set of colors isn't quite right
const MegaminxColors = {
  BEIGE: { value: '#ffffbb' },
  DARK_BLUE: { value: '#0047ab' },
  GRAY: { value: '#999999' },
  GREEN: { value: '#007c00' },
  LIGHT_BLUE: { value: '#00bfff' },
  LIGHT_GREEN: { value: '#88e200' },
  ORANGE: Colors.ORANGE,
  PINK: Colors.PINK,
  PURPLE: Colors.PURPLE,
  RED: Colors.RED,
  WHITE: Colors.WHITE,
  YELLOW: Colors.YELLOW,
};

function getPuzzleType(puzzle) {
  switch (puzzle) {
    case Puzzles['3x3x3']:
    case Puzzles['5x5x5']:
      return Type.CUBE_NET;
    case Puzzles.Megaminx:
      return Type.MEGAMINX_NET;
    case Puzzles.Square1:
      return Type.SQUARE1_NET;
    default:
      throw new Error(`Unsupported puzzle ${puzzle}`);
  }
}

function getPuzzleSize(puzzle) {
  switch (puzzle) {
    case Puzzles['3x3x3']:
      return 3;
    case Puzzles['5x5x5']:
      return 5;
    case Puzzles.Megaminx:
      return 2;
    case Puzzles.Square1:
      return null;
    default:
      throw new Error(`Unsupported puzzle ${puzzle}`);
  }
}

function getColorScheme(puzzle) {
  switch (puzzle) {
    case Puzzles['3x3x3']:
    case Puzzles['5x5x5']:
    case Puzzles.Square1:
      return {
        U: Colors.WHITE,
        D: Colors.YELLOW,
        F: Colors.GREEN,
        B: Colors.BLUE,
        L: Colors.ORANGE,
        R: Colors.RED,
      };
    case Puzzles.Megaminx:
      // How are these arranged?
      // First chunk is the top half and second chunk is the bottom half
      // Top half (place U face on top, F face in front)
      // U face, F face, then goes counter-clockwise
      // Bottom half (place d face on top, b face in front)
      // d face, b face (directly opposite from F face), then goes counter-clockwise
      return {
        U: MegaminxColors.WHITE,
        F: MegaminxColors.GREEN,
        R: MegaminxColors.RED,
        BR: MegaminxColors.DARK_BLUE,
        BL: MegaminxColors.YELLOW,
        L: MegaminxColors.PURPLE,

        d: MegaminxColors.GRAY,
        b: MegaminxColors.LIGHT_GREEN,
        br: MegaminxColors.PINK,
        dr: MegaminxColors.BEIGE,
        dl: MegaminxColors.LIGHT_BLUE,
        bl: MegaminxColors.ORANGE,
      }
    default:
      throw new Error(`Unsupported puzzle ${puzzle}`);
  }
}

function getCompatibleScramble(puzzle, scramble) {
  switch (puzzle) {
    case Puzzles.Megaminx: {
      // Bug in the library: ++ and -- should do two-fifths turn, but
      // the library does only one-fifth.
      // Easy fix -- double each move!
      const moves = [];
      scramble
        .split(' ')
        .forEach(move => {
          moves.push(move);
          if (move[0] !== 'U') {
            moves.push(move);
          }
        });
      return moves.join(' ');
    }
    case Puzzles.Square1:
      return scramble.replaceAll(', ', ',');
    default:
      return scramble;
  }
}

function getPopoutWidth(puzzle) {
  switch (puzzle) {
    case Puzzles['3x3x3']:
      return 250;
    case Puzzles['5x5x5']:
      return 300;
    case Puzzles.Megaminx:
      return 350;
    case Puzzles.Square1:
      return 250;
    default:
      throw new Error(`Unsupported puzzle ${puzzle}`);
  }
}

function getPopoutHeight(puzzle) {
  switch (puzzle) {
    case Puzzles['3x3x3']:
      return 200;
    case Puzzles['5x5x5']:
      return 250;
    case Puzzles.Megaminx:
      return 250;
    case Puzzles.Square1:
      return 250;
    default:
      throw new Error(`Unsupported puzzle ${puzzle}`);
  }
}

function PreviewModal({puzzle, scramble, onHide}) {
  const containerRef = useRef();

  const {
    width: viewportWidth,
    height: viewportHeight,
  } = useViewportDimensions();

  const renderMode = viewportWidth < MODAL_THRESHOLD
    ? RenderMode.MODAL
    : RenderMode.POPOUT;

  const width = renderMode === RenderMode.MODAL
    ? viewportWidth
    : getPopoutWidth(puzzle);

  const height = renderMode === RenderMode.MODAL
    ? viewportHeight
    : getPopoutHeight(puzzle);

  useKeyboardListener(event => {
    if (event.key === 'Escape') {
      onHide();
    }
  });

  useEffect(() => {
    const container = containerRef.current;
    const canvas = document.createElement('div');
    container.appendChild(canvas);

    PNG(canvas, getPuzzleType(puzzle), {
      width,
      height,
      strokeWidth: 0.008,
      puzzle: {
        alg: getCompatibleScramble(puzzle, scramble),
        size: getPuzzleSize(puzzle),
        scheme: getColorScheme(puzzle),
      },
    });

    return () => container.removeChild(canvas);
  }, [puzzle, scramble, width, height]);

  return (
    <div
      className={renderMode === RenderMode.MODAL ? styles.modalContainer : styles.popoutContainer}
      ref={containerRef}
    >
      <button className={styles.closeBtn} onClick={onHide}>&#x2715;</button>
    </div>
  );
}

export default PreviewModal;
