import { useState } from 'react';
import useKeyboardListener from './useKeyboardListener';
import PreviewModal from './PreviewModal';
import styles from './PreviewButton.module.css';

function PreviewButton({puzzle, scramble}) {
  const [showModal, setShowModal] = useState(false);

  const onShowPreview = event => {
    event.stopPropagation();
    setShowModal(true);
  };

  const onHide = () => setShowModal(false);

  useKeyboardListener(event => {
    if (event.key === 'p') {
      setShowModal(true);
    }
  });

  return (
    <div>
      {!showModal && <button className={styles.button} onClick={onShowPreview}><i className="fal fa-search fa-2x" /></button>}
      {showModal && <PreviewModal puzzle={puzzle} scramble={scramble} onHide={onHide} />}
    </div>
  );
}

export default PreviewButton;
