import Puzzles from './Puzzles';
import GenericFormattedScramble from './GenericFormattedScramble';
import MegaminxFormattedScramble from './MegaminxFormattedScramble';

function FormattedScramble({puzzle, scramble}) {
  switch (puzzle) {
    case Puzzles.Megaminx:
      return <MegaminxFormattedScramble scramble={scramble} />;
    default:
      return <GenericFormattedScramble scramble={scramble} />;
  }
}

export default FormattedScramble;
