import { useState, useCallback } from 'react';

function usePersistedState(storageKey, init) {
  const [state, setState] = useState(
    localStorage.getItem(storageKey) || init,
  );

  const setAndPersistState = useCallback(updater => {
    if (typeof updater === 'string') {
      localStorage.setItem(storageKey, updater);
      setState(updater);
    } else {
      setState(prevState => {
        const updatedState = updater(prevState);
        localStorage.setItem(storageKey, updatedState);
        return updatedState;
      })
    }
  }, [setState, storageKey]);

  return [state, setAndPersistState];
}

export default usePersistedState;
