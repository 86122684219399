import { useMemo } from 'react';
import styles from './MegaminxFormattedScramble.module.css';

function MegaminxFormattedScramble({scramble}) {
  const rows = useMemo(() => {
    const output = [];
    let currentRow = [];

    scramble
      .split(' ')
      .forEach(move => {
        currentRow.push(move);

        if (move[0] === 'U') {
          output.push(currentRow.join(' '));
          currentRow = [];
        }
      });

    return output;
  }, [scramble]);

  return (
    <div className={styles.container}>
      {rows.map((row, idx) => <div key={idx}>{row}</div>)}
    </div>
  );
}

export default MegaminxFormattedScramble;
