import { useCallback, useState } from "react";
import useKeyboardListener from "./useKeyboardListener";
import usePersistedState from "./usePersistedState";
import Puzzles from "./Puzzles";
import scramblers from "./scramblers";
import PuzzleSelector from "./PuzzleSelector";
import FormattedScramble from "./FormattedScramble";
import PreviewButton from "./PreviewButton";
import styles from './App.module.css';

function App() {
  const [puzzle, setPuzzle] = usePersistedState("PUZZLE", Puzzles['3x3x3']);
  const [scramble, setScramble] = useState(scramblers[puzzle]());

  const nextScrambleKeyboardListener = useCallback(event => {
    if (event.key === ' ')  {
      setScramble(scramblers[puzzle]());
    }
  }, [puzzle]);

  useKeyboardListener(nextScrambleKeyboardListener);

  const onPuzzleChange = puzzle => {
    setPuzzle(puzzle);
    setScramble(scramblers[puzzle]());
  };

  const getNextScramble = () => {
    setScramble(scramblers[puzzle]());
  };

  return (
    <div className={styles.container}>
      <div className={styles.selectorContainer}>
        <PuzzleSelector value={puzzle} onChange={onPuzzleChange} />
      </div>
      <div className={styles.scrambleContainer} onClick={getNextScramble}>
        <FormattedScramble puzzle={puzzle} scramble={scramble} />
      </div>
      <div className={styles.previewContainer}>
        <PreviewButton puzzle={puzzle} scramble={scramble} />
      </div>
    </div>
  );
}

export default App;
