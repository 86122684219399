const ROWS = 7;
const RD_MOVES_PER_ROW = 10;

const FACES = ["R", "D"];
const TWO_FIFTHS_MOVE = ["++", "--"];
const U_MOVE = ["", "'"];

function getRandomBit() {
  return Math.floor(Math.random() * 2);
}

function scramble() {
  const moves = [];

  for (let row = 0; row < ROWS; row++) {
    for (let move = 0; move < RD_MOVES_PER_ROW; move++) {
      moves.push(
        FACES[move % 2] + TWO_FIFTHS_MOVE[getRandomBit()],
      );
    }

    moves.push(
      "U" + U_MOVE[getRandomBit()],
    );
  }

  return moves.join(' ');
}

export default scramble;
